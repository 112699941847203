import { Link } from './../util/router';
import { useAuth } from './../util/auth';
import { Bars3Icon, MagnifyingGlassIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { useRouter } from './../util/router';
import { useMedia } from 'react-use';
import { useTextSearchStore } from '../stores';

const Navbar = () => {
  const auth = useAuth();
  const { location } = useRouter();
  const isWide = useMedia('(min-width: 480px)');
  const { isInputOpen, setIsInputOpen } = useTextSearchStore();

  const navigation = [
    { name: 'Lists', href: '/lists' },
    { name: 'My Account', href: '/settings/general' },
    { name: 'About', href: '/about' },
    //{ name: 'Pricing', href: '/pricing' },
  ];

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav className="mx-auto flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link
            to="/"
            className="Showrunner text-secondary-red text-2xl font-medium uppercase leading-none"
          >
            showrunner
          </Link>
        </div>
        <div className="flex">
          {location.pathname === '/' &&
            !isWide &&
            (isInputOpen ? (
              <FunnelIcon
                className="stroke-2 text-secondary-red h-5 w-5 mr-2 my-auto"
                aria-hidden="true"
                onClick={() => setIsInputOpen()}
              />
            ) : (
              <MagnifyingGlassIcon
                className="stroke-2 text-secondary-red h-5 w-5 mr-2 my-auto"
                aria-hidden="true"
                onClick={() => setIsInputOpen()}
              />
            ))}
          <div className="dropdown dropdown-end">
            <div tabIndex={0} role="button">
              {auth.user?.user_metadata?.avatar_url ? (
                <div className="avatar">
                  <div className="w-8 rounded-full">
                    <img src={auth.user?.user_metadata?.avatar_url} alt="" />
                  </div>
                </div>
              ) : (
                <Bars3Icon className="stroke-2 text-secondary-red h-6 w-6" aria-hidden="true" />
              )}
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content menu bg-white rounded-box z-[1] w-52 p-2 shadow text-[0.9rem]"
            >
              {auth.user && (
                <li className="py-1">
                  <p className="font-semibold ">Hi {auth.user.name}</p>
                </li>
              )}
              {auth.user ? (
                navigation.map(item => (
                  <li key={item.name}>
                    <Link to={item.href}>{item.name}</Link>
                  </li>
                ))
              ) : (
                <li key={'About'}>
                  <Link to={'/about'}>{'About'}</Link>
                </li>
              )}
              {!auth.user ? (
                <li className="py-1">
                  <Link to="/auth/signin" className="font-semibold text-gray-900">
                    Sign in <span aria-hidden="true">&rarr;</span>
                  </Link>
                </li>
              ) : (
                <li className="py-1">
                  <Link
                    to="auth/signout"
                    className="font-semibold text-secondary-red"
                    onClick={e => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign out <span aria-hidden="true">&rarr;</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
