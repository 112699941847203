import { Fragment } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useDebounce } from 'react-use';
import { useTextSearchStore } from '../stores';

/**
 * a search text menu component.
 *
 * @returns JSX.Element
 */
const SearchTextMenu = ({ postion = 'dropdown-end' }) => {
  const { rawQuery, setQuery, setRawQuery, resetTextQuery } = useTextSearchStore();

  /**
   * Debounce the search query to avoid making too many requests
   * while the user is typing. This helps to reduce the number of
   * requests made to the server. Thus improving performance.
   */
  const [, cancel] = useDebounce(
    () => {
      setQuery(rawQuery);
    },
    1200,
    [rawQuery]
  );

  return (
    <Fragment>
      <div className={`my-auto dropdown ${postion}`}>
        <div tabIndex={0} role="button">
          <MagnifyingGlassIcon
            className="stroke-2 text-secondary-red h-5 w-5 mr-2"
            aria-hidden="true"
          />
        </div>

        <ul
          tabIndex={0}
          className="dropdown-content z-50 menu bg-base-100 rounded-box w-60 md:w-[800px] max-w-screen-md p-2 mt-3 shadow"
        >
          <label className="input flex items-center gap-2">
            <input
              type="text"
              className="grow"
              placeholder="Search"
              value={rawQuery}
              onChange={({ currentTarget }) => {
                setRawQuery(currentTarget.value);
              }}
            />
            <XMarkIcon
              className="stroke-2 text-secondary-red h-5 w-5"
              aria-hidden="true"
              onClick={resetTextQuery}
            />
          </label>
        </ul>
      </div>
    </Fragment>
  );
};

export default SearchTextMenu;
