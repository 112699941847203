import { useEffect, useState, useCallback, Fragment } from 'react';
import { MapPinIcon, BoltIcon, CalendarIcon, HeartIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/20/solid';
import { useRouter } from '../../util/router';
import { getShowById, getListsByOwner, getShowsByIDs } from '../../util/util';
import { useAuth } from '../../util/auth';
import Map, { Marker, FullscreenControl, GeolocateControl } from 'react-map-gl/maplibre';
import { formatDate, formatTimeRange } from '../../util/data-factory';
import SecondaryShowTile from '../../components/SecondaryShowTile';
import SaveShowModal from '../../components/SaveShowModal';
import HomeFooter from '../../components/HomeFooter';
import Meta from '../../components/Meta';
import analytics from '../../util/analytics';

const ShowPage = () => {
  const auth = useAuth();
  const router = useRouter();
  const { id } = router.query;
  const [showInfo, setShowinfo] = useState();
  const [expandedImageIndex, setExpandedImageIndex] = useState(null);
  const [likedShows, setLikedShows] = useState(new Set());
  const [savingItem, setSavingItem] = useState(false);
  const [userLists, setUserLists] = useState([]);
  const [similarShows, setSimilarShows] = useState([]);
  const [nearbyShows, setNearbyShows] = useState([]);
  const [error, setError] = useState();

  const fetchShowByID = useCallback(async () => {
    try {
      const show = await getShowById(id);
      setShowinfo(show[0]);

      const similar = await getShowsByIDs(show[0].similar_shows);
      const nearby = await getShowsByIDs(show[0].nearby_shows);
      setSimilarShows(similar);
      setNearbyShows(nearby);
    } catch (error) {
      console.error(error);
      setError('Failed to fetch show');
    }
  }, [id]);

  const fetchUserLists = useCallback(async () => {
    try {
      if (auth.user) {
        const lists = await getListsByOwner(auth.user.id);
        setUserLists(lists);
        if (lists.length) {
          const likedShows = new Set();
          lists.forEach(list => {
            list.shows.forEach(show => {
              likedShows.add(show);
            });
          });
          setLikedShows(likedShows);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [auth.user]);

  // ********************************* //
  // ************ Effects ************ //
  // ********************************* //

  useEffect(() => {
    fetchUserLists();
  }, [auth.user, fetchUserLists]);

  useEffect(() => {
    if (id) {
      fetchShowByID();
    }
  }, [id]);

  // ********************************* //
  // ******* Internal Funcs ********** //
  // ********************************* //

  const formatTags = () => {
    if (!showInfo) {
      return '';
    }
    const { show_style, show_medium, show_subject_matter, firs_ny_solo, female_astist } = showInfo;
    const display = [...show_style, ...show_medium, ...show_subject_matter].join(' | ');
    return `${firs_ny_solo ? 'First NY Solo |' : ''}${
      female_astist ? 'Female Artist |' : ''
    } ${display}`;
  };

  const formatArtistNames = () => {
    if (!showInfo) {
      return '';
    }
    return showInfo.artist_names.length > 1
      ? showInfo.artist_names.join(', ')
      : showInfo.artist_names[0];
  };

  const onSaved = () => {
    setSavingItem(false);
    fetchUserLists();
  };

  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  return (
    <Fragment>
      <Meta title="Show" />
      <section className="w-screen h-[100px] overflow-hidden bg-black/90"></section>
      <section className="w-screen h-[240px] sm:h-[300px] overflow-hidden bg-white">
        <div className="flex w-full h-full max-h-[400px] max-w-[90rem] p-4 md:p-8 my-auto mx-auto overflow-auto">
          <div className="w-full content-end">
            <h1 className="uppercase text-2xl md:text-4xl font-medium">
              {showInfo?.display_title}
            </h1>
            <p className="text-lg md:text-xl text-black/70 font-medium">{formatArtistNames()}</p>
            <p className="text-secondary-red text-sm md:text-lg">{formatTags()}</p>
            <div className="flex justify-between">
              <div className="flex justify-between text-xs md:text-sm my-3">
                <div className="flex flex-1">
                  <div className="flex mr-4 items-center">
                    <MapPinIcon className="w-5" /> {showInfo?.venues.venue_name}
                  </div>
                  <div className="flex mr-4 items-center">
                    <CalendarIcon className="w-5 mr-1" /> {formatDate(showInfo?.start_date, true)} -{' '}
                    {formatDate(showInfo?.end_date, true)}
                  </div>
                  {showInfo?.recommended ? (
                    <div className="flex mr-4 items-center">
                      <BoltIcon className="w-5" /> Trending
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="my-auto cursor-pointer" onClick={() => setSavingItem(true)}>
                {likedShows.has(Number(id)) ? (
                  <HeartIconSolid className="w-6" />
                ) : (
                  <HeartIcon className="w-6" />
                )}
              </div>
            </div>
            {showInfo?.opening_date && (
              <div className="flex normal-case text-xs md:text-sm">
                <p className="mr-2">Opening Reception: </p>
                <p className="mr-2 text-black/50">{formatDate(showInfo.opening_date, true)}</p>
                <p className="text-black/50">
                  {formatTimeRange(showInfo.opening_start_time, showInfo.opening_end_time)}
                </p>
              </div>
            )}
          </div>
        </div>
      </section>

      <div className="py-1">
        <div
          className={`grid grid-flow-col auto-cols-max w-full p-4 md:p-8 gap-5 overflow-auto items-center justify-start md:justify-center`}
        >
          {showInfo?.image_urls?.map((url, index) => (
            <div className="mx-auto cursor-pointer" key={index}>
              {index === expandedImageIndex ? (
                <div className="fixed top-0 left-0 bg-white w-full h-full flex items-center justify-center z-50">
                  <img
                    src={url}
                    alt="show_image"
                    className="h-screen w-auto md:h-full object-contain"
                    onClick={() => setExpandedImageIndex(null)}
                  />
                </div>
              ) : (
                <img
                  className="min-w-full m-auto max-h-[220px] md:max-h-[390px]"
                  src={url}
                  alt="show_image"
                  onClick={() => setExpandedImageIndex(index)}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <section className="w-screen mt-1 overflow-hidden bg-secondary">
        <div className="flex w-full h-full max-w-[90rem] p-4 md:px-8 md:py-2 my-auto mx-auto">
          <div className="grid grid-cols-3 md:grid-cols-4 gap-4 border-b border-b-gray pb-4">
            {showInfo?.long_description && (
              <div className="col-span-3 md:col-span-3 my-auto">
                <h3 className="text-lg md:text-2xl mb-1">Intro</h3>
                <p className="text-md sm:text-lg pr-2 text-black/70 indent-8">
                  {showInfo?.long_description}
                </p>
              </div>
            )}
            <div className="col-span-2 md:col-auto md:mr-auto">
              <h3 className="text-lg md:text-2xl">Location</h3>
              <div className="overflow-auto text-black/70 px-4 md:px-0">
                <p>{showInfo?.venues.venue_name}</p>
                <div className="flex">
                  <p className="mr-2">{showInfo?.venues.neighborhood},</p>
                  <p>{showInfo?.venues.city}</p>
                </div>
                <br />
                <div className="flex">
                  <p className="mr-2">{showInfo?.venues.address1}</p>
                  <p>{showInfo?.venues.phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-[90rem] px-4 md:px-8 md:py-2 my-auto mx-auto">
          <h3 className="text-lg md:text-2xl mb-2">Similar Shows</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-2 md:px-8 w-full border-b border-b-gray">
            {similarShows.length > 0 && (
              <Fragment>
                <div className="flex flex-col">
                  {similarShows.slice(0, 2).map(show => (
                    <SecondaryShowTile key={show.show_id} show={show} today={today} />
                  ))}
                </div>

                <div className="flex flex-col">
                  {similarShows.slice(2, 4).map(show => (
                    <SecondaryShowTile key={show.show_id} show={show} today={today} />
                  ))}
                </div>

                <div className="flex flex-col">
                  {similarShows.slice(4, 6).map(show => (
                    <SecondaryShowTile key={show.show_id} show={show} today={today} />
                  ))}
                </div>
              </Fragment>
            )}
          </div>
        </div>

        <div className="max-w-[90rem] px-4 md:px-8 md:py-2 my-auto mx-auto">
          <h3 className="text-lg md:text-2xl my-2">Nearby Shows</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-2 md:px-8 w-full">
            {nearbyShows.length > 0 && (
              <Fragment>
                <div className="flex flex-col">
                  {nearbyShows.slice(0, 2).map(show => (
                    <SecondaryShowTile key={show.show_id} show={show} today={today} />
                  ))}
                </div>

                <div className="flex flex-col">
                  {nearbyShows.slice(2, 4).map(show => (
                    <SecondaryShowTile key={show.show_id} show={show} today={today} />
                  ))}
                </div>

                <div className="flex flex-col">
                  {nearbyShows.slice(4, 6).map(show => (
                    <SecondaryShowTile key={show.show_id} show={show} today={today} />
                  ))}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </section>
      {/*<div className="rounded-xl basis-2/3 overflow-hidden">
            {latitude && longitude && <VenueMap latitude={latitude} longitude={longitude} />}
          </div> 
        */}
      {savingItem && <SaveShowModal onDone={onSaved} showId={Number(id)} userLists={userLists} />}
      <HomeFooter hideMobile />
    </Fragment>
  );
};

export default ShowPage;

function VenueMap({ latitude, longitude }) {
  return (
    <Map
      initialViewState={{
        longitude: longitude ?? -74.004,
        latitude: latitude ?? 40.748,
        zoom: 15,
      }}
      center={[latitude, longitude]}
      maxZoom={19}
      style={{ width: 220, height: 280 }}
      mapStyle="https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json"
      maplibreLogo={false}
    >
      <GeolocateControl />
      <Marker longitude={longitude} latitude={latitude} anchor="center" />
      <FullscreenControl position="top-left" />
    </Map>
  );
}
