import { useRef, Fragment } from 'react';
import { Link } from '../util/router';
import { BoltIcon, CalendarIcon } from '@heroicons/react/24/outline';

import maplibregl from 'maplibre-gl';
import { Source, Layer, Map, Popup, GeolocateControl, NavigationControl } from 'react-map-gl';
import { formatDate, trendingShowsLayer, regularShowsLayer } from '../util/data-factory';

import PIN_ICON from '../assets/images/pin.png';
import PIN_ICON_REG from '../assets/images/pin2.png';

const ShowrunnerMap = ({
  mainMapViewState,
  featureData,
  mapImageInfo,
  mapPopupInfo,
  mapTopTagsInfo,
  onPinClick,
  onMove,
  closeShowMapDialog,
}) => {
  const mapRef = useRef();

  return (
    <Map
      ref={mapRef}
      {...mainMapViewState}
      mapLib={maplibregl}
      mapStyle="https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json"
      interactiveLayerIds={[regularShowsLayer.id, trendingShowsLayer.id]}
      minZoom={1.25}
      maxZoom={20}
      onClick={e => !mapImageInfo && onPinClick(e, mapRef)}
      onMove={onMove}
      onLoad={async () => {
        const map = mapRef.current.getMap();
        if (!map.hasImage('pin-icon')) {
          const image = await map.loadImage(PIN_ICON);
          map.addImage('pin-icon', image.data);
        }
        if (!map.hasImage('pin-icon-reg')) {
          const image = await map.loadImage(PIN_ICON_REG);
          map.addImage('pin-icon-reg', image.data);
        }
      }}
    >
      <GeolocateControl trackUserLocation />
      <NavigationControl />
      <Source
        id="my-data"
        type="geojson"
        data={featureData}
        clusterMaxZoom={11}
        clusterRadius={100}
      >
        <Layer {...regularShowsLayer} />
        <Layer {...trendingShowsLayer} />
      </Source>

      {mapImageInfo && (
        <Popup
          anchor="right"
          className="rounded-xl bg-red h-20 w-20"
          closeOnClick
          latitude={mapPopupInfo?.lat}
          longitude={mapPopupInfo?.lng}
          onClose={closeShowMapDialog}
        >
          <div className="bg-white h-auto rounded-xl overflow-hidden relative p-2">
            {mapImageInfo.length ? (
              <div className="h-40 md:h-60 w-40 md:w-60">
                {mapPopupInfo?.recommended && (
                  <div className="absolute top-4 left-4 py-0 px-2 bg-off-white flex">
                    <BoltIcon className="w-4" />
                    <small>Trending</small>
                  </div>
                )}
                <img className="h-[95%] mx-auto object-cover" alt="_image" src={mapImageInfo[0]} />
              </div>
            ) : (
              <Fragment></Fragment>
            )}

            {mapPopupInfo && (
              <Link href="/show/[id]" to={`/show/${mapPopupInfo.show_id}`}>
                <div className="flex flex-col mt-2 p-2 text-wrap w-40 md:w-60 cursor-pointer">
                  <p className="uppercase text-sm">{mapPopupInfo.display_title}</p>
                  <p className="text-sm text-black/60">at {mapPopupInfo.venues.venue_name}</p>
                  <p className="text-md text-black/60">{mapPopupInfo.venues.address1}</p>
                  <p className="text-xs text-secondary-red">{mapTopTagsInfo?.join(' | ')}</p>
                  <div className="flex mt-1">
                    <CalendarIcon className="w-4 mr-1" />
                    <p className="text-xs text-slate-500">
                      {formatDate(mapPopupInfo.start_date)} - {formatDate(mapPopupInfo.end_date)}
                    </p>
                  </div>
                </div>
              </Link>
            )}
          </div>
        </Popup>
      )}
    </Map>
  );
};

export default ShowrunnerMap;
