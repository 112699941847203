import React from 'react';

const FilterStats = ({ shows, recommendedShows, resetFilters }) => {
  return (
    <div className="flex justify-evenly p-5 max-w-lg mx-auto">
      <div className="flex bg-white p-1 md:p-2 md:px-5 rounded-lg w-auto">
        <div className="text-xs md:text-md mx-1">
          <strong>{shows.length}</strong> shows found
        </div>
      </div>

      <div className="flex bg-black p-1 md:p-2 md:px-5 rounded-lg w-auto">
        <div className="text-xs md:text-md text-slate-200 mx-1">
          {recommendedShows.length} trending shows in this search
        </div>
      </div>

      <button className="btn btn-xs bg-off-white text-xs" onClick={resetFilters}>
        Clear all
      </button>
    </div>
  );
};

export default FilterStats;
