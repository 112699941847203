import React from 'react';
import Meta from './../components/Meta';
import HomeFooter from '../components/HomeFooter';

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <section className="py-10 px-4 my-10">
        <div className="container mx-auto mt-12">
          <div className="prose prose-a:text-blue-600 max-w-none">
            <h2>Showrunner: Created by Art Lovers, for Art Lovers</h2>
            <p>
              At Showrunner, we believe that people should see as much art in their lives as
              possible. Our team—comprising artists, a gallery owner, and avid collectors—is driven
              by a tremendous passion for art.
            </p>
            <p>
              In our constant search for the art that inspires us and exploration of the NYC gallery
              scene, we were frustrated with the deep limitations of the available platforms. So we
              decided to build the tool we always wished for.
            </p>
            <h2>Why We Started</h2>
            <p>
              No existing service seemed to offer comprehensive coverage of art shows. We struggled
              to find a platform that allowed us to search by the criteria that mattered to us most,
              such as medium or theme. Questions like "Which sculptural exhibitions are currently
              on?" or "Where can I find figurative paintings?" had no easy answers.
            </p>
            <p>
              Sharing discoveries was another challenge. We resorted to texting notes, sharing lists
              of galleries, and marking Google Maps—none of which felt intuitive or particularly
              helpful.
            </p>
            <p>
              So, we built Showrunner—your definitive guide to art exhibitions in New York City,
              with more cities to come soon. Showrunner lets you filter art categories, explore the
              most comprehensive set of exhibitions, create personalized lists, and share them
              easily.
            </p>
            <h2>Designed for Everyone</h2>
            <p>
              Whether you’re an artist or collector, a dealer or student, a curator or gallery-goer,
              Showrunner is crafted for you. We’re constantly adding new features to make
              discovering art easier and more enjoyable.
            </p>
            <h2>Join Us</h2>
            <p>
              Stay updated and join the journey by signing up for the Showrunner newsletter.
              Encounter issues or can’t find an exhibition? Email us at{' '}
              <a href="mailto:info@showrunner.co">info@showrunner.co</a>, and we’ll make it right.
            </p>
            <p>Looking forward to exploring art with you.</p>
            <p>
              <strong>With love, Showrunner</strong>
            </p>
          </div>
        </div>
      </section>

      <HomeFooter sticky />
    </>
  );
}

export default AboutPage;
