import { create } from 'zustand';

const useFiltersStore = create(set => ({
  showListView: true,
  open: new Set(),
  selectedMediums: new Set(),
  selectedStyles: new Set(),
  selectedSubject: new Set(),
  selectedLocation: new Set(),
  sortedByRelevance: true,

  setShowListView: selected => set({ showListView: selected }),
  setAllArtMedium: () => set({ selectedMediums: new Set() }),
  addToFilter: (key, value) =>
    set(state => {
      if (key === 'open') {
        // Only allow one open filter at a time
        const newSet = new Set();
        if (!state.open.has(value)) {
          newSet.add(value);
        }
        return { ...state, open: newSet };
      } else {
        const newSet = new Set(state[key]);
        newSet.add(value);
        return { ...state, [key]: newSet };
      }
    }),

  removeFromFilter: (key, value) =>
    set(state => {
      const newSet = new Set(state[key]);
      newSet.delete(value);
      return { ...state, [key]: newSet };
    }),

  sortedByRelevanceToggle: () =>
    set(state => ({
      ...state,
      sortedByRelevance: !state.sortedByRelevance,
    })),

  resetFilters: () =>
    set({
      open: new Set(),
      selectedMediums: new Set(),
      selectedStyles: new Set(),
      selectedSubject: new Set(),
      selectedLocation: new Set(),
    }),
  // Derived state to keep track of the number of items in each set
  getCounts: () => {
    const state = useFiltersStore.getState();
    return {
      openCount: state.open.size,
      selectedMediumsCount: state.selectedMediums.size,
      selectedStylesCount: state.selectedStyles.size,
      selectedSubjectCount: state.selectedSubject.size,
      selectedLocationCount: state.selectedLocation.size,
    };
  },
}));

export default useFiltersStore;
