import { forwardRef } from 'react';
import { useFiltersStore } from '../stores';

const MobileFilterCard = forwardRef(({ filterMap, currentKey, options, floating }, ref) => {
  const {
    open,
    selectedMediums,
    selectedLocation,
    selectedSubject,
    selectedStyles,
    setAllArtMedium,
    addToFilter,
    removeFromFilter,
  } = useFiltersStore();

  const FILTERS_HASH = {
    MEDIUM: { key: 'selectedMediums', filter: selectedMediums },
    'ON VIEW': { key: 'open', filter: open },
    NEIGHBORHOOD: { key: 'selectedLocation', filter: selectedLocation },
    SUBJECT: { key: 'selectedSubject', filter: selectedSubject },
    STYLE: { key: 'selectedStyles', filter: selectedStyles },
  };

  const handleSelect = (filter, key, option) => {
    if (option === 'All Art') {
      setAllArtMedium();
      return;
    }

    if (filter.has(option)) {
      removeFromFilter(key, option);
    } else {
      addToFilter(key, option);
    }
  };

  return (
    <div
      ref={ref}
      className={`${
        floating && 'absolute left-2 top-32 md:left-[40%] md:top-28 z-40 w-60'
      } card w-full bg-slate-100 text-neutral-content my-2 shadow-xl z-40`}
    >
      <div className="card-body items-center text-center p-2">
        <div className="p-0">
          {options.map((option, idx) => (
            <div
              key={idx}
              className={`badge badge-lg m-1 cursor-pointer ${
                filterMap[currentKey].selected.has(option)
                  ? 'bg-secondary-red text-white'
                  : 'badge-primary'
              }`}
              onClick={() =>
                handleSelect(FILTERS_HASH[currentKey].filter, FILTERS_HASH[currentKey].key, option)
              }
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default MobileFilterCard;
