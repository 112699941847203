import React from 'react';
import Auth from './Auth';
import AuthFooter from './AuthFooter';

function AuthSection(props) {
  // Options by auth type
  const optionsByType = {
    signup: {
      // Top Title
      title: 'Get yourself an account',
      // Button text
      buttonAction: 'Sign up',
      // Footer text and links
      showFooter: true,
      signinText: 'Already have an account?',
      signinAction: 'Sign in',
      signinPath: '/auth/signin',
      // Terms and privacy policy agreement
      showAgreement: true,
      termsPath: '/legal/terms-of-service',
      privacyPolicyPath: '/legal/privacy-policy',
    },
    signin: {
      title: 'Welcome back',
      buttonAction: 'Sign in',
      showFooter: true,
      signupAction: 'Create an account',
      signupPath: '/auth/signup',
      forgotPassAction: 'Forgot Password?',
      forgotPassPath: '/auth/forgotpass',
    },
    forgotpass: {
      title: 'Get a new password',
      buttonAction: 'Reset password',
      showFooter: true,
      signinText: 'Remember your password?',
      signinAction: 'Sign in',
      signinPath: '/auth/signin',
    },
    changepass: {
      title: 'Choose a new password',
      buttonAction: 'Change password',
    },
  };

  // Ensure we have a valid auth type
  const type = optionsByType[props.type] ? props.type : 'signup';

  // Get options object for current auth type
  const options = optionsByType[type];

  return (
    <section className="mt-10">
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 ">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            {props.type == 'signin' && 'Sign in to your account'}
            {props.type == 'signup' && 'Sign up for an account'}
            {props.type == 'forgotpassword' && 'Reset your password'}
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-8 shadow sm:rounded-lg sm:px-12">
            <Auth
              type={type}
              buttonAction={options.buttonAction}
              providers={props.providers}
              afterAuthPath={props.afterAuthPath}
              key={type}
            />
          </div>
          {options.showFooter && <AuthFooter type={type} {...options} />}
        </div>
      </div>
    </section>
  );
}

export default AuthSection;
