import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDebounce } from 'react-use';
import { useTextSearchStore } from '../stores';

const StatefulTextSearch = () => {
  const { rawQuery, setQuery, setRawQuery, resetTextQuery } = useTextSearchStore();

  /**
   * Debounce the search query to avoid making too many requests
   * while the user is typing. This helps to reduce the number of
   * requests made to the server. Thus improving performance.
   */
  const [, cancel] = useDebounce(
    () => {
      setQuery(rawQuery);
    },
    1200,
    [rawQuery]
  );
  return (
    <label className="input flex items-center">
      <input
        type="text"
        className="grow"
        placeholder="Search"
        value={rawQuery}
        onChange={({ currentTarget }) => {
          setRawQuery(currentTarget.value);
        }}
      />
      <XMarkIcon
        className="stroke-2 text-secondary-red h-5 w-5"
        aria-hidden="true"
        onClick={resetTextQuery}
      />
    </label>
  );
};

export default StatefulTextSearch;
